export const prayer=`
༡༽ འགྲོ་ལ་ཕན་བཞེད་ཐུགས་རྗེས་རབ་བསྐྲུན་པའི། །སྤངས་རྟོགས་སྐྱོབ་པ་མཆོག་བརྙེས་ལྷ་ཡི་ལྷ། །རྟེན་འབྱུང་གཏམ་གྱིས་འགྲོ་རྣམས་འདྲེན་མཛད་པའི། །ཐུབ་དབང་སྨྲ་བའི་ཉི་མར་མགོས་ཕྱག་འཚལ། །

༢༽ རྒྱལ་ཡུམ་དགོངས་དོན་མཐའ་བྲལ་དེ་ཉིད་དོན། །རྟེན་འབྱུང་རིགས་ཚུལ་ཟབ་མོས་གསལ་མཁས་པ། །རྒྱལ་བའི་ལུང་བཞིན་ཐེག་མཆོག་དབུ་མའི་སྲོལ། །འབྱེད་མཛད་ཀླུ་སྒྲུབ་ཞབས་ལ་གསོལ་བ་འདེབས། །

༣༽ དེ་སྲས་ཐུ་བོ་མཁས་ཤིང་གྲུབ་པའི་མཆོག །ཕྱི་ནང་གྲུབ་མཐའ་རྒྱ་མཚོའི་ཕ་མཐར་སོན། །ཀླུ་སྒྲུབ་གཞུང་འཛིན་ཀུན་གྱི་གཙུག་ནོར་དཔལ། །རྒྱལ་སྲས་འཕགས་པ་ལྷ་ལ་གསོལ་བ་འདེབས། །

༤༽ འཕགས་པའི་དགོངས་པ་རྟེན་འབྱུང་མཐར་ཐུག་དོན། །བཏགས་ཡོད་མིང་རྐྱང་ཙམ་གྱི་ཟབ་མོའི་གནད། །གསལ་མཛད་གྲུབ་པ་མཆོག་གི་སར་གཤེགས་པ། །སངས་རྒྱས་བསྐྱངས་ཀྱི་ཞབས་ལ་གསོལ་བ་འདེབས། །

༥༽ བདེན་པའི་དངོས་པོ་སྐྱེ་སོགས་མཐའ་བཀག་ཅིང་། །ཚད་མ་མཐུན་སྣང་ཕྱི་དོན་བཞེད་པ་ཡི། །གྲུབ་མཐའི་སྲོལ་གཏོད་ཡོངས་རྫོགས་པཎྡི་ཏ། །སློབ་དཔོན་ལེགས་ལྡན་འབྱེད་ལ་གསོལ་བ་འདེབས། །

༦༽ རྟེན་འབྲེལ་རྐྱེན་ཉིད་འདི་པ་ཙམ་ཉིད་ཀྱིས། །མཐའ་གཉིས་སེལ་བའི་སྣང་སྟོང་དབུ་མའི་ཚུལ། །ཟབ་རྒྱས་འདོམས་མཁས་མདོ་སྔགས་ཡོངས་རྫོགས་ལམ། །རྒྱས་མཛད་ཟླ་བ་གྲགས་པར་གསོལ་བ་འདེབས། །

༧༽ ངོ་མཚར་རྨད་བྱུང་སྙིང་རྗེ་ཆེན་པོའི་ལམ། །ཟབ་དང་རྒྱ་ཆེའི་རིགས་ཚུལ་རྣམ་མང་གིས། །སྐལ་བཟང་གདུལ་བྱའི་ཚོགས་ལ་སྟོན་མཁས་པ། །རྒྱལ་སྲས་ཞི་བ་ལྷ་ལ་གསོལ་བ་འདེབས། །

༨༽ གདུལ་བྱའི་ཁམས་བཞིན་གཉིས་སྟོང་དབུ་མའི་ལམ། །སྲོལ་གཏོད་དབུ་ཚད་རིགས་ཚུལ་འབྱེད་མཁས་ཤིང་། །ཁ་བའི་ལྗོངས་སུ་རྒྱལ་བསྟན་སྤེལ་མཛད་པ། ། མཁན་ཆེན་ཞི་བ་འཚོ་ལ་གསོལ་བ་འདེབས། །

༩༽ མཐའ་བྲལ་དབུ་མའི་ལྟ་དང་ཞི་ལྷག་ཟུང་། །སྒོམ་རིམ་མདོ་རྒྱུད་བཞིན་དུ་ལེགས་བཀྲལ་ནས། །གངས་ལྗོངས་རྒྱལ་བསྟན་འཁྲུལ་མེད་གསལ་མཛད་པ། །པད་མའི་ངང་ཚུལ་ཞབས་ལ་གསོལ་བ་འདེབས། །

༡༠༽ བྱམས་པས་རྗེས་བཟུང་ཐེག་ཆེན་སྡེ་སྣོད་ཀུན། །ལེགས་པར་སྤེལ་མཁས་རྒྱ་ཆེན་ལམ་སྟོན་ཞིང་། །རྒྱལ་བའི་ལུང་བཞིན་རྣམ་རིག་ཤིང་རྟའི་སྲོལ། །འབྱེད་མཛད་ཐོགས་མེད་ཞབས་ལ་གསོལ་བ་འདེབས། །

༡༡༽ ཆོས་མངོན་སྡེ་བདུན་གཉིས་སྟོང་སྲོལ་བཟུང་ནས། །བྱེ་མདོ་རྣམ་རིག་གྲུབ་མཐའ་གསལ་མཛད་པ། །ཀུན་མཁྱེན་གཉིས་པར་གྲགས་པའི་མཁས་པའི་མཆོག །སློབ་དཔོན་དབྱིག་གཉེན་ཞབས་ལ་གསོལ་བ་འདེབས། །

༡༢༽ ཐུབ་པའི་གཞུང་ལུགས་དངོས་སྟོབས་རིགས་པ་ཡིས། །སྟོན་ཕྱིར་ཚད་མའི་སྒོ་བརྒྱ་ལེགས་ཕྱེ་ནས། །རྣམ་དཔྱོད་བློ་མིག་སྦྱིན་མཛད་ཚད་མ་པ། །ཕྱོགས་ཀྱི་གླང་པོའི་ཞབས་ལ་གསོལ་བ་འདེབས། །

༡༣༽ ཕྱི་ནང་ཚད་མའི་གནད་ཀུན་ལེགས་དགོངས་ཤིང་། །མདོ་སེམས་ཟབ་རྒྱས་ལམ་ཀུན་རིགས་ལམ་ནས། །ངེས་སྟེར་རྨད་བྱུང་ཆོས་ཚུལ་འདོམས་མཁས་པའི། །ཆོས་ཀྱི་གྲགས་པའི་ཞབས་ལ་གསོལ་བ་འདེབས། །

༡༤༽ ཐོགས་མེད་སྐུ་མཆེད་ལས་འོངས་ཤེར་ཕྱིན་དོན། །ཡོད་མེད་མཐའ་བྲལ་དབུ་མའི་སྲོལ་བཞིན་དུ། །རྒྱན་གྱི་གཞུང་དོན་སྣང་བའི་སྒྲོན་མེ་སྦར། །འཕགས་པ་གྲོལ་སྡེའི་ཞབས་ལ་གསོལ་བ་འདེབས། །

༡༥༽ ཡུམ་དོན་འབྱེད་ལ་རྒྱལ་བའི་ལུང་བསྟན་ཐོབ། །མི་ཕམ་མགོན་པོའི་མན་ངག་ཇི་བཞིན་དུ། །ཡུམ་གསུམ་ཤེར་ཕྱིན་གཞུང་མཆོག་གསལ་མཛད་པའི། །སློབ་དཔོན་སེང་གེ་བཟང་པོར་གསོལ་བ་འདེབས། །

༡༦༽ འདུལ་བ་འབུམ་སྡེའི་དགོངས་དོན་ལེགས་བསྡུས་ནས། །ཐམས་ཅད་ཡོད་སྨྲའི་ལུགས་བཞིན་སོ་སོ་ཐར། །མ་ནོར་ལེགས་འདོམས་བརྟན་མཁས་མཆོག་གྱུར་པ། །ཡོན་ཏན་འོད་ཀྱི་ཞབས་ལ་གསོལ་བ་འདེབས། །

༡༧༽ བསླབ་གསུམ་ཡོན་ཏན་ནོར་བུའི་མཛོད་ལ་དབང་། །འདུལ་བསྟན་དྲི་མེད་རིང་དུ་སྤེལ་བའི་སླད། །རྒྱ་ཆེན་གཞུང་དོན་ལེགས་བཀྲལ་འདུལ་འཛིན་མཆོག །ཤཱཀྱ་འོད་ཀྱི་ཞབས་ལ་གསོལ་བ་འདེབས། །

༡༨༽ ཐུབ་གསུང་ཟབ་རྒྱས་བཀའ་སྲོལ་མ་ལུས་པ། །སྐྱེས་བུ་གསུམ་གྱི་ལམ་དུ་འདོམས་མཛད་དེ། །གངས་ལྗོངས་ཐུབ་བསྟན་སྤེལ་བའི་དྲིན་ཅན་རྗེ། །ཇོ་བོ་ཨ་ཏི་ཤ་ལ་གསོལ་བ་འདེབས། །

༡༩༽ དེ་ལྟར་འཛམ་གླིང་རྒྱན་གྱུར་མཁས་པའི་ཕུལ། །ངོ་མཚར་ལེགས་བཤད་འབྱུང་གནས་མཆོག་རྣམས་ལ། །མི་ཕྱེད་དང་བའི་ཡིད་ཀྱིས་གསོལ་བཏབ་པས། །བདག་རྒྱུད་སྨིན་ཅིང་གྲོལ་བར་བྱིན་གྱིས་རློབས། །

༢༠༽ གཞི་ཡི་གནས་ཚུལ་བདེན་གཉིས་དོན་ཤེས་པས། །བདེན་བཞིས་འཁོར་བ་འཇུག་ལྡོག་ཇི་བཞིན་ངེས། །ཚད་མས་དྲངས་པའི་སྐྱབས་གསུམ་དད་པ་བརྟན། །ཐར་ལམ་རྩ་བ་ཚུགས་པར་བྱིན་གྱིས་རློབས། །

༢༡༽ སྡུག་ཀུན་ཉེར་ཞིའི་ཐར་པ་དོན་གཉེར་བའི། །ངེས་འབྱུང་བློ་དང་འགྲོ་རྣམས་སྐྱོབ་འདོད་པའི། །ཕྱོགས་མཐས་གཏུགས་པའི་སྙིང་རྗེའི་རྩ་བ་ཅན། །བཅོས་མིན་བྱང་སེམས་འབྱོངས་པར་བྱིན་གྱིས་རློབས། །

༢༢༽ ཤིང་རྟ་ཆེན་པོའི་གཞུང་གི་དོན་རྣམས་ལ། །ཐོས་བསམ་སྒོམ་པས་ཕ་རོལ་ཕྱིན་པ་དང་། །རྡོ་རྗེ་ཐེག་པའི་ཟབ་གནད་ལམ་ཀུན་ལ། །ངེས་པ་བདེ་བླག་རྙེད་པར་བྱིན་གྱིས་རློབས། །

༢༣༽ སྐྱེ་ཞིང་སྐྱེ་བར་བསླབ་གསུམ་ལྡན་པའི་རྟེན། །ལེགས་ཐོབ་བཤད་དང་སྒྲུབ་པས་ལུང་རྟོགས་བསྟན། །འཛིན་ཅིང་སྤེལ་ལ་ཤིང་རྟ་ཆེ་རྣམས་དང་། །མཚུངས་པར་བསྟན་ལ་བྱ་བ་ བྱེད་པར་ཤོག །

༢༤༽ འདུས་སྡེ་ཀུན་ཏུ་ཐོས་བསམ་བཤད་སྒྲུབ་ཀྱི། །བྱ་བས་དུས་འདའ་ལོག་འཚོ་ཡོངས་སྤངས་པའི། །དམ་པའི་མཁས་གྲུབ་རབ་ཏུ་འཕེལ་བ་ཡིས། །འཛམ་གླིང་ས་ཆེན་རྟག་ཏུ་མཛེས་གྱུར་ཅིག །

༢༥༽ དེ་མཐུས་མདོ་སྔགས་ཡོངས་རྫོགས་ས་ལམ་བགྲོད། །དོན་གཉིས་ལྷུན་གྲུབ་རྣམ་མཁྱེན་རྒྱལ་བ་ཡི། །གོ་འཕང་མྱུར་བ་ཉིད་དུ་ཐོབ་གྱུར་ནས། །ནམ་མཁའ་ཇི་སྲིད་འགྲོ་བའི་དོན་བྱེད་ཤོག །

`